/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE, MESSAGE_ITEM_TYPE, UNSUPPORTED_MIME_TYPE } from "@/constants"
import { downloadFile, generateMD5ForFile, getBaseURL } from "@/utils"

const path = "message-items"

export default {
  /**
   * This action will load a particular messageItem.
   * @param {*} context it is the store.
   * @param {*} payload it is the filter criteria
   */
  async loadMessageItems(context, payload) {
    context.commit("setLoadingMessageItems", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getMessageItemsResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getMessageItemsResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateMessageItems", getMessageItemsResponse.data)
      } else {
        context.commit("setMessageItems", getMessageItemsResponse.data)
      }
    } else {
      // something went wrong while getting messageItems
    }

    context.commit("setLoadingMessageItems", false)
  },

  /**
   * This action saves the messageItems of type other than text.
   * @param {*} context is the store.
   * @param {*} payload is id of message.
   */
  async uploadAttachments(context, payload) {
    context.commit("setUploadingAttachments", true)
    const files    = payload.files
    const language = context.rootGetters["auth/reporterLanguage"]

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const messageItems = new Array()
    for (const file of files) {
      const md5Result = generateMD5ForFile(file)
      let type        = file.type

      if (file.name.toLowerCase().endsWith(MESSAGE_ITEM_TYPE.MSG_EXTENSION) && type === "") {
        type = UNSUPPORTED_MIME_TYPE.MSG
      }

      const messageItemPostResponse = await httpClient.post(undefined, {
        value: {
          name: file.name,
          size: file.size,
          md5 : await md5Result
        },
        languageId: language.id,
        messageId : payload.id,
        type
      })
      if (messageItemPostResponse.status === HTTP_STATUS_CODE.OK) {
        messageItems.push(messageItemPostResponse.data)
        await context.dispatch("getPresignedUrlAndUploadFilesToS3", { file: file,  messageItem: messageItemPostResponse.data })
      } else {
        // something went wrong while saving attachments
      }
    }
    context.commit("setUploadingAttachments", false)
    context.commit("updateMessageItems", messageItems)
  },

  /**
   * This action removes attachments in parallel api calls
   * @param {*} context is the store.
   * @param {*} payload is the array of messageItems to be removed.
   */
  async removeAttachments(context, payload) {
    context.commit("setNotExistingFilesRemoved", false)

    const messageItems   = context.getters.messageItems
    const deletePromises = payload.map(messageItem =>
      context.dispatch("removeAttachment", messageItem.id)
    )
    await Promise.all(deletePromises)
    const newMessageItems = messageItems.filter(messageItem =>
      !payload.some(deletedItem => deletedItem.id === messageItem.id))
    context.commit("setMessageItems", newMessageItems)

    context.commit("setNotExistingFilesRemoved", true)
  },

  /**
   * This action removes the messageItem of type other than text.
   * @param {*} context is the store.
   * @param {*} payload is id of messageItem.
   */
  async removeAttachment(context, payload) {
    const httpClient               = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)
    const messageItems             = context.getters.messageItems
    const deleteAttachmentResponse = await httpClient.delete(`/${payload}`)
    if (deleteAttachmentResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      const newMessageItems = messageItems.filter(messageItem => messageItem.id !== payload)
      context.commit("deleteMessageItems", newMessageItems)
    }
  },

  /**
   * This action uploads attachments to S3 bucket.
   * @param {*} context is the store.
   * @param {*} payload is list of attachments.
   */
  async getPresignedUrlAndUploadFilesToS3(context, payload) {
    const httpClient           = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)
    const preSignedUrlResponse = await httpClient.get(`/${payload.messageItem.id}/upload-url`)
    if (preSignedUrlResponse.status === HTTP_STATUS_CODE.OK) {

      const form = new FormData()
      Object.keys(preSignedUrlResponse.data.fields).forEach(key =>
        form.append(key, preSignedUrlResponse.data.fields[key]))
      form.append("file", payload.file)

      const headers = {
        "Content-MD5": Buffer.from(payload.messageItem.value.md5, "hex").toString("base64")
      }

      const response = await fetch(preSignedUrlResponse.data.url, { method: "POST", body: form, headers })
      if (response.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
        payload.messageItem.fileExists = true
        await context.commit("updateMessageItems", [payload.messageItem])
        return true
      } else {
        await context.dispatch("removeAttachment", payload.messageItem.id)
        context.dispatch("shared/notify", {
          type: "error",
          text: "NOTIFICATION_UPLOAD_ERROR"
        }, { root: true })
        return false
      }
    } else {
      await context.dispatch("removeAttachment", payload.messageItem.id)
      // something went wrong while getting pre signed url
    }
  },

  /**
   * This action downloads file associated with message item
   * @param {*} context is the store.
   * @param {*} id message item id whose associated file has to be downloaded.
   * @param {*} md5 checksum value to be compared with for integrity.
   * @param {*} name of the file.
   */
  async downloadFile(context, { id, md5, name }) {
    context.commit("setDownloadingFile", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getDownloadURLResponse = await httpClient.get(`/${id}/download-url`)

    if (getDownloadURLResponse.status === HTTP_STATUS_CODE.OK) {
      await downloadFile(getDownloadURLResponse.data, md5, name)
    }
    context.commit("setDownloadingFile", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setLoadingMessageItems", undefined)
    context.commit("resetMessageItems")
    context.commit("setUploadingAttachments", undefined)
    context.commit("setDownloadingFile", undefined)
  }
}